module.exports = [{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-97614044-1","head":false,"anonymize":true},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#5764c6","showSpinner":false},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://3f5af944a0484d3baeb112b9db235280@sentry.io/294491","environment":"production","enabled":true},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"vgpvywdq"},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
