// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-dpa-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/dpa.js" /* webpackChunkName: "component---src-pages-dpa-js" */),
  "component---src-pages-features-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-security-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-terms-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/atlassian/pipelines/agent/build/.cache/data.json")

